import React from "react"
import { Table } from "./Table"
import { TableStyles } from "../style/globalStyles"

export const CounsellorTable = ({ counsellors }) => {
  const STATUS = "status" === 1 ? true : false;
  const columns = React.useMemo(
    () => [
      {
        Header: "Counsellor ID",
        accessor: "listenerId",
      },
      {
        Header: "Counsellor Name",
        accessor: "listenerName",
      },
      {
        Header: "Speciality",
        accessor: "descriptionText",
      },
      {
        Header: "Status",
        accessor: (counsellor) => counsellor.status === 1 ? "true" : "false",
      },
      {
        Header: "Verified",
        accessor: (counsellor) => counsellor.verified ? "true" : "false",
      },
    ],

    []
  )

  var tableRender = (
    <Table
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          onClick: (e) => console.log("row clicked", rowInfo),
        }
      }}
      // Pass the page url here to navigate to onClick
      // healthCoach={true}
      path={"/counsellors/counsellor"}
      columns={columns}
      data={counsellors}
    />
  )

  return <TableStyles>{counsellors ? tableRender : " "}</TableStyles>
}

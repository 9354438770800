import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Layout } from "../../components/Layout"
import { PatientsSection } from "../../style/patients"
import { CounsellorTable } from "../../components/CounsellorTable"
import { fetchCounsellors } from "../../state/counsellor/counsellorActions"

function CounsellorContainer() {
  const user = useSelector((state) => state?.login?.user);
  const counsellorList = useSelector((state) => state?.counsellors?.counsellor);
  const dispatch = useDispatch()

  console.log(counsellorList, '990090')

  useEffect(() => {
    dispatch(fetchCounsellors(user))
  }, []);

  return (
    <Layout>
      <PatientsSection>
        <h1>Counsellors</h1>

        {counsellorList?.length === 0 ? (
          <>
            <br />
            <br />
            <h4>{!user.hasOwnProperty("organizationId") ? "No counsellor found" : "No counsellor found"}</h4>
          </>
        ) : (
          <>
            {/* <h1>Patients </h1> */}
            <CounsellorTable counsellors={counsellorList} />
          </>
        )}
      </PatientsSection>
    </Layout>
  )
}

export default CounsellorContainer;

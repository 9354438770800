import styled from "styled-components"
import { Section } from "./globalStyles"

export const PatientsSection = styled.div`
  display: block;
  margin: 2rem;
  padding: 0;
  h1 {
    font-size: 1.7rem;
  }
  h2 {
    font-size: 1.5rem;
  }
`
